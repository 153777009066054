<template>
  <section class="banner__search--section">
    <transition name="fade">
      <HeaderSearch
        :isSearchOnBanner="true"
        :isSearchFocused="isSearchFocused"
        @setFocus="handleSearchFocus"
        v-if="!getHeaderFixedStatus"
      />
    </transition>
    <Banner
      :bannerTitle="bannerTitle"
      :bannerContent="bannerContent"
      :bannerBtnText="bannerBtnText"
      :bannerDesktopImage="bannerDesktopImage"
      :bannerMobileImage="bannerMobileImage"
      :bannerBtn="true"
      bannerDesktopCustomHeight="430"
      @onClick="register(getRedirectURI)"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import authenticationMixin from "@/core/mixins/authentication";
import Banner from "./Banner.vue";
import HeaderSearch from "@/components/Layout/LandingPageLayout/HeaderSearch.vue";
export default {
  components: { Banner, HeaderSearch },
  mixins: [authenticationMixin],
  computed: {
    ...mapGetters(["allConfig", "getHeaderFixedStatus"])
  },
  data() {
    return {
      bannerTitle: this.$t("landing_page.banner_section.title"),
      bannerContent: this.$t("landing_page.banner_section.content"),
      bannerBtnText: this.$t("general.register"),
      bannerDesktopImage: require("@/assets/images/landing/banner/banner-desktop.png"),
      bannerMobileImage: require("@/assets/images/landing/banner/banner-mobile.png"),
      isSearchFocused: false
    };
  },
  methods: {
    handleSearchFocus(value) {
      this.isSearchFocused = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.banner__search--section {
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
