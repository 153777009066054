var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"headerSearch",class:[
    'header-search',
    {
      'search-expanded': _vm.isSearchFocused,
      'banner-search': _vm.isSearchOnBanner,
      'header-fixed': _vm.isHeaderSticky
    }
  ]},[_c('div',{class:['search-input', { 'search-focused': _vm.isSearchFocused }]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"searchQuery",staticClass:"input-box",attrs:{"type":"text","placeholder":_vm.$t('landing_page.search.placeholder2')},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},_vm.getSearchedCourseList],"focus":_vm.onFocus}}),_c('img',{attrs:{"src":_vm.searchIcon,"alt":"search"}})]),(_vm.searchQuery !== '' && _vm.isSearchFocused)?_c('div',{staticClass:"search__list--container"},[(_vm.courseList.length > 0)?[_c('div',{staticClass:"search-label"},[_c('span',{staticClass:"label-heading"},[_vm._v(" "+_vm._s(_vm.$t("course.breadcrumb.course"))+" ")]),_c('span',{staticClass:"label-link",on:{"click":_vm.goToCourses}},[_vm._v(" "+_vm._s(_vm.$t("landing_page.search.all_results"))+" ")])]),_c('ul',{staticClass:"search-list"},_vm._l((_vm.courseList),function(course){return _c('li',{key:course.id,on:{"click":function($event){return _vm.onClick(course)}}},[_vm._v(" "+_vm._s(course.title)+" ")])}),0)]:_c('div',{staticClass:"no-result"},[_c('img',{attrs:{"src":_vm.noSearchIcon,"alt":"no result"}}),_vm._v(" "+_vm._s(_vm.$t("landing_page.search.no_course"))+" ")])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }