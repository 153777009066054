import utils from "@/router/utils";
export default {
  computed: {
    getRedirectURI() {
      let baseURL = `https://${window.location.host}`,
        redirectUri;
      if (this.$route.query && this.$route.query.next) {
        redirectUri = `${baseURL}${this.$route.query.next}`;
      } else if (this.$route.name === "Courses Detail") {
        redirectUri = `${baseURL}${this.$route.fullPath}`;
      } else {
        redirectUri = this.allConfig.SSO.redirectUri;
      }
      return redirectUri;
    }
  },
  methods: {
    login(redirectUri = null) {
      let uri;
      redirectUri ? (uri = redirectUri) : (uri = utils.getRedirectUri());
      this.$keycloak.login({
        redirectUri: uri,
        locale: this.language
      });
    },
    register(redirectUri = null) {
      let uri;
      redirectUri ? (uri = redirectUri) : (uri = utils.getRedirectUri());
      this.$keycloak.login({
        redirectUri: uri,
        action: "register",
        locale: this.language
      });
    },
    logout() {
      localStorage.removeItem("onboardingStatus");
      const redirectUri =
        this.$keycloak.endpoints.logout() +
        "?id_token_hint=" +
        this.$keycloak.idToken +
        "&post_logout_redirect_uri=" +
        encodeURIComponent(window.location.origin);
      window.location.href = redirectUri;
    }
  }
};
