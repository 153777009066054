<template>
  <div
    :class="[
      'header-search',
      {
        'search-expanded': isSearchFocused,
        'banner-search': isSearchOnBanner,
        'header-fixed': isHeaderSticky
      }
    ]"
    ref="headerSearch"
  >
    <div :class="['search-input', { 'search-focused': isSearchFocused }]">
      <input
        type="text"
        ref="searchQuery"
        v-model="searchQuery"
        @input="getSearchedCourseList"
        @focus="onFocus"
        :placeholder="$t('landing_page.search.placeholder2')"
        class="input-box"
      />
      <img :src="searchIcon" alt="search" />
    </div>
    <div
      class="search__list--container"
      v-if="searchQuery !== '' && isSearchFocused"
    >
      <template v-if="courseList.length > 0">
        <div class="search-label">
          <span class="label-heading">
            {{ $t("course.breadcrumb.course") }}
          </span>
          <span class="label-link" @click="goToCourses">
            {{ $t("landing_page.search.all_results") }}
          </span>
        </div>
        <ul class="search-list">
          <li
            v-for="course in courseList"
            :key="course.id"
            @click="onClick(course)"
          >
            {{ course.title }}
          </li>
        </ul>
      </template>
      <div class="no-result" v-else>
        <img :src="noSearchIcon" alt="no result" />
        {{ $t("landing_page.search.no_course") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isSearchFocused: Boolean,
    isHeaderSticky: Boolean,
    isSearchOnBanner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: "",
      courseList: [],
      isSearchLoading: false,
      searchIcon: require("@/assets/images/search/search.svg"),
      noSearchIcon: require("@/assets/images/landing/search/no-search.svg")
    };
  },
  mounted() {
    setTimeout(() => {
      document.addEventListener("click", this.handleClickOutside);
    }, 0);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    getSearchedCourseList() {
      if (this.searchQuery.trim() === "") {
        this.courseList = [];
        this.isSearchLoading = false;
        return;
      }
      this.isSearchLoading = true;
      let params = {
        pageNumber: 1,
        pageSize: 20,
        searchText: this.searchQuery ? this.searchQuery : "",
        language: this.logoLanguage
      };
      this.$store
        .dispatch("getSearchList", params)
        .then(response => {
          this.courseList = response.results;
          this.isSearchLoading = false;
        })
        .catch(() => {
          this.courseList = [];
          this.isSearchLoading = false;
        });
    },
    onFocus() {
      this.$emit("setFocus", true);
    },
    handleClickOutside(event) {
      const searchContainer = this.$refs.headerSearch;
      if (searchContainer && !searchContainer.contains(event.target)) {
        this.$emit("setFocus", false);
      }
    },
    onClick(course) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: course.id },
        query: { source: course.source }
      });
      document.getElementById("app").scrollIntoView();
    },
    resetSearchResult() {
      this.searchQuery = "";
    },
    goToCourses() {
      this.$router.push({
        name: "Courses",
        query: { search: this.searchQuery }
      });
      document.getElementById("app").scrollIntoView();
    }
  }
};
</script>
<style lang="scss" scoped>
.header-search {
  margin-left: auto;
  margin-right: 16px;
  width: 300px;
  position: relative;
  &.search-expanded {
    width: 100%;
    margin-left: 16px;
  }
  .search-input {
    border-radius: 100px;
    border: 1px solid $brand-neutral-200;
    background-color: $brand-neutral-0;
    padding: 7px 16px 7px 48px;
    position: relative;
    width: 100%;
    &.search-focused {
      border-color: $brand-primary;
      background-color: $brand-primary-75;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
    .input-box {
      border: none;
      padding: 0;
      @include body-regular($brand-primary);
      width: 100%;
      background-color: transparent;
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
      }
      &::placeholder {
        color: $brand-neutral-300;
      }
    }
  }
  .search__list--container {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    max-height: 214px;
    border-radius: 8px;
    background-color: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    padding: 8px;
    overflow-y: scroll;
    text-align: left;
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      border: none;
      background-color: $brand-neutral-75;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: none;
      background-color: $brand-neutral-300;
    }
    .search-label {
      @include horizontal-space-between;
      @include label-medium(500);
      margin-bottom: 8px;
      .label-heading {
        color: $brand-neutral-900;
      }
      .label-link {
        color: $brand-primary-400;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .search-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        @include body-medium;
        color: $brand-neutral-700;
        border-radius: 4px;
        padding: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        &:not(:first-child) {
          margin-top: 8px;
        }
        &:hover {
          background-color: $brand-primary-100;
        }
      }
    }
    .no-result {
      @include flex-center;
      @include body-medium;
      color: $brand-neutral-700;
      padding: 8px;
      img {
        margin-right: 8px;
      }
    }
  }
  &.banner-search {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px auto -30px;
    width: 600px;
    z-index: 1;
    .search-input {
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
      padding: 17px 16px 17px 48px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .header-search {
    &.header-fixed:not(.search-expanded) {
      display: none;
    }
    &.search-expanded {
      position: absolute;
      right: 0;
      width: calc(100% - 188px);
    }
    &:not(.banner-search) {
      .search-input {
        .input-box {
          &::placeholder {
            visibility: hidden;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .header-search {
    &.banner-search {
      width: 500px;
      margin-bottom: -35px;
    }
  }
}
@media screen and (max-width: 767px) {
  .header-search {
    width: 100%;
    margin: 0;
    padding: 16px 0px;
    &.search-expanded {
      margin: 0;
    }
    &.banner-search {
      width: 343px;
      padding: 0;
      margin: 0 auto -35px;
    }
    .search-input {
      padding: 13px 16px 13px 48px;
    }
    &:not(.banner-search) {
      .search__list--container {
        top: 100%;
        box-shadow: none;
        left: -16px;
        width: calc(100% + 32px);
        background-color: $brand-neutral-0;
        border-radius: 0;
        height: 275px;
        max-height: unset;
        z-index: 1;
      }
    }
  }
}
</style>
